import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  list: []
}

export const ContactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    setContact: (state, action) => {
      const { id } = action.payload
      const findContact = state.list.findIndex(el => el.id === id)
      if (findContact !== -1) {
        const list = state.list.map((contact) => {
          if (contact.id === id) {
            return { ...contact, ...action.payload }
          }
          return { ...contact }
        })
        return {
          ...state,
          list
        }
      }
      return {
        ...state,
        list: [...state.list, action.payload]
      }
    },
    addContact: (state, action) => {
      return { 
        ...state,
        list: [...state.list, action.payload]
      }
    },
    updateContact: (state, action) => {
      return {
        ...state,
        list: state.list.map((contact) => {
          if (contact?.id === action.payload.id) {
            return { ...contact, ...action.payload }
          }
          return contact
        })
      }
    },
    removeContact: (state, action) => {
      return {
        ...state,
        list: state.list.filter((contact) => contact.id !== action.payload)
      }
    },
    setContacts: (state, action) => {
      return {
        ...state,
        list: action.payload
      }
    }
  }
})

export const { setContact, addContact, updateContact, removeContact, setContacts } = ContactsSlice.actions

export default ContactsSlice.reducer
