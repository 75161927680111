import { createSlice } from '@reduxjs/toolkit'
import { default as Storage } from '@src/@core/storage'

const defaultStages = ["new"]

const initialState = {
  list: [],
  filteredList: [],
  filters: {
    stage: defaultStages
  }
}

export const JobsSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    setJobs: () => {
      
    },
    addJob: (state, action) => {
      return { 
        ...state,
        list: [...state.list, action.payload],
        filteredList: [...state.list, action.payload].filter((job) => {
          return state.filters.stage.includes(job.status) 
        })
      }
    },
    updateJob: (state, action) => {
      const updatedList = state.list.map((job) => {
        if (job?.id === action.payload.id) {
          return { ...job, ...action.payload }
        }
        return job
      })

      return { 
        ...state,
        list: updatedList,
        filteredList: updatedList.filter((job) => {
          return state.filters.stage.includes(job.status) 
        })
      }
    },
    updateJobStageCount: (state, action) => {
      const {id, stage, operation} = action.payload
      const updatedList = state.list.map((job) => {
        if (job?.id === id) {
          let newValue = job.stages[stage] 
          if (operation === 'increment') {
            newValue = parseInt(newValue) + 1
          }
          if (operation === 'decrement' && newValue > 0) {
            newValue = parseInt(newValue) - 1
          }
          return {...job, stages: {...job.stages, [stage]: newValue}}
        }
        return job
      })

      return { 
        ...state,
        list: updatedList,
        filteredList: updatedList.filter((job) => {
          return state.filters.stage.includes(job.status) 
        })
      }
    },
    removeJob: (state, action) => {
      return {
        ...state,
        list: state.list.filter((job) => job.id !== action.payload),
        filteredList: [...state.filteredList].filter((job) => job.id !== action.payload)
      }
    },
    setJobs: (state, action) => {
      return {
        ...state,
        list: action.payload,
        filteredList: action.payload.filter((job) => {
          return state.filters.stage.includes(job.status) 
        })
      }
    },
    setFilters: (state, action) => {
      return {
        ...state,
        filters: action.payload
      }
    },
    updateFilters: (state, action) => {
      const {type, filter} = action.payload
      let newFilters
      if (!state.filters[type].includes(filter)) {
        newFilters = {...state.filters, ...{[type]: [...state.filters[type], filter]}}
      } else {
        newFilters = {...state.filters, ...{[type]: state.filters[type].filter(e => e !== filter)}}
      }

      Storage.setJobFilters(JSON.stringify(newFilters))
      return { 
        ...state,
        filters: newFilters,
        filteredList: state.list.filter((job) => {
          return newFilters.stage.includes(job.status)
        })
      }
    }
  }
})

export const { setJobs, addJob, updateJob, updateJobStageCount, removeJob, setFilters, updateFilters } = JobsSlice.actions

export default JobsSlice.reducer
