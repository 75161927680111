import { createSlice } from '@reduxjs/toolkit'

const initialFilters = {
  stage: [],
  job: [],
  qualification: null
}

const initialState = {
  list: [],
  filteredList: [],
  resumeList: [],
  filters: initialFilters,
  sortBy: {
    field: "createdAt",
    value: "desc"
  }
}

const filterCandidates = (filters, candidates) => {
  const list =  [...candidates].map((candidate) => {
    let jobs = candidate.jobs
    if (filters.stage.length) {
      const isJobInStage = jobs.find((job) => filters.stage.includes(job.stage))
      if (!isJobInStage) {
        return false
      }
      jobs = jobs.filter((job) => filters.stage.includes(job.stage))
    }
    if (filters.job.length) {
      const isTalentPool = jobs.length === 0 && filters.job.includes(null)
      const isConnectedToJob = jobs.find((job) => filters.job.includes(job.id))
      if (!isConnectedToJob && !isTalentPool) {
        return false
      }
      jobs = jobs.filter((job) => {
        return filters.job.includes(job.id)
      })
      if (!jobs.length && !isTalentPool) {
        return false
      }
    }
    if (filters.qualification !== null) {
      if (!jobs.find((job) => filters.qualification === job.qualification)) {
        return false
      }
    }

    return {...candidate, jobs}
  }).filter((item) => item !== false)
  return list
}

const sortCandidates = (list, sortBy) => {
  const {value, field} = sortBy
  return [...list].sort((prev, next) => {
    if (value === "asc") {
      return prev[field] - next[field]
    }
    return next[field] - prev[field] 
  })
}

export const CandidatesSlice = createSlice({
  name: 'candidates',
  initialState,
  reducers: {
    setCandidates: (state, action) => {
      return {
        ...state,
        list: action.payload,
        filteredList: sortCandidates(filterCandidates(state.filters, action.payload), state.sortBy)
      }
    },
    addCandidate: (state, action) => {
      return {
        ...state,
        list: [...state.list, action.payload],
        filteredList: sortCandidates(filterCandidates(state.filters, [...state.list, action.payload]), state.sortBy)
      }
    },
    updateCandidate: (state, action) => {
      if (!state.list.length) {
        return {
          ...state,
          list: [...state.list, action.payload],
          filteredList: sortCandidates(filterCandidates(state.filters, [...state.list, action.payload]), state.sortBy)
        }
      }
      const updatedList = state.list.map((candidate) => {
        if (candidate?.id === action.payload.id) {
          return {
            ...candidate, 
            ...action.payload
          }
        }
        return candidate
      })
      return { 
        ...state,
        list: updatedList,
        filteredList: sortCandidates(filterCandidates(state.filters, updatedList), state.sortBy)
      }
    },
    updateCandidateJob: (state, action) => {
      if (!state.list.length) {
        return {
          ...state,
          list: [...state.list, action.payload],
          filteredList: sortCandidates(filterCandidates(state.filters, [...state.list, action.payload]), state.sortBy)
        }
      }
      const updatedList = state.list.map((candidate) => {
        if (candidate?.id === action.payload.id) {
          return {
            ...candidate, 
            jobs: [...candidate.jobs].map((job) => {
              if (job?.id === action.payload.jobId) {
                return {...job, ...action.payload.params}
              }
              return job
            })
          }
        }
        return candidate
      })
      return { 
        ...state,
        list: updatedList,
        filteredList: sortCandidates(filterCandidates(state.filters, updatedList), state.sortBy)
      }
    },
    addResumeCandidate: (state, action) => {
      return {
        ...state,
        resumeList: [...state.resumeList, action.payload]
      }
    },
    clearResumeCandidates: (state) => {
      return {
        ...state,
        resumeList: []
      }
    },
    updateFilters: (state, action) => {
      const {type, filter} = action.payload

      const newFilters = {...state.filters, ...{[type]: filter}}
      return { 
        ...state,
        filters: newFilters,
        filteredList: sortCandidates(filterCandidates(newFilters, state.list), state.sortBy)
      }
    },
    setSortBy: (state, action) => {
      const sortedList = sortCandidates(state.filteredList, action.payload)
      return { 
        ...state,
        filteredList: sortedList,
        sortBy: action.payload
      }
    },
    clearFilters:(state) => {
      return { 
        ...state,
        filteredList: sortCandidates(state.list, state.sortBy),
        filters: initialFilters
      }
    }
  }
})

export const {
  setCandidates,
  addCandidate,
  updateCandidate,
  updateCandidateJob,
  addResumeCandidate,
  clearResumeCandidates,
  updateFilters,
  setSortBy,
  clearFilters
} = CandidatesSlice.actions

export default CandidatesSlice.reducer
